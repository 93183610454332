<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import { addYears, formatWithOptions } from 'date-fns/fp'
//import { eo } from 'date-fns/locale'
//import image from "@/assets/images/users/user.png";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Historiques transactions",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Historiques transactions",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
       ordersData: [],
      transaction:{
        total:"",
        echouee:"",
        reussie:""
      },
       totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "profil", sortable: true, label:"Profil" },
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "depart", sortable: true, label: "Départ" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "moyen", sortable: true, label: "Moyen" },
        { key: "date", sortable: true , label:"Date paiment"},
        { key: "place", sortable: true , label:"Place.s"},
        { key: "about", label:"StatutAAAA"},
      ],
      transactionData:[],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },

  async mounted(){
    const historiqueTransac = await apiRequest(
      "GET",
      "admin/transaction/info",
      undefined,
      false
    );
    //console.log("historique:",historiqueTransac.data)
    if(historiqueTransac && historiqueTransac.data){

      this.transaction.total = historiqueTransac.data.total
        ? historiqueTransac.data.total
        : "0";
      this.transaction.echouee = historiqueTransac.data.echecs
        ? historiqueTransac.data.echecs
        : "0";
        this.transaction.reussie = historiqueTransac.data.reussies
        ? historiqueTransac.data.reussies
        : "0";
    }

    const listeTransaction= await apiRequest(
      "GET",
      "admin/transactions",
      undefined,
      false
    );

     if(listeTransaction && listeTransaction.data){
     //console.log("Liste:",listeTransaction.data)
     
     const TransactListTable = listeTransaction.data.map((transaction)=>{
       return{
         profil: transaction.client.personne.lastname+" "+transaction.client.personne.firstname,
         trajet: transaction.trajet.lieuDepart.nom+" >> "+transaction.trajet.lieuArrivee.nom,
         date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
         montant: transaction.trajet.prix,
         moyen: transaction.moyen,
         depart: new Date(transaction.trajet.dateHeureDepart).toLocaleString("fr-FR"),
         place: transaction.place,
         about: transaction.statut=="PAYER" ? "PAID" : transaction.statut
       };
     });
     this.transactionData = TransactListTable
    }
    
  },

  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-primary"
                    >
                      <template v-slot:header>
                        <h5 class="my-0 mb-5">
                          Total des transactions
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">{{transaction.total}}</h1>
                      </div>
                    </b-card>
                </div>
                 <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-info"
                    >
                      <template v-slot:header>
                        <h5 class="my-0 mb-4">
                            Total des transactions réussies
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">{{transaction.reussie}}</h1>
                      </div>
                    </b-card>
                </div>
                 <div class="col-md-2">
                    <b-card
                      header-class="bg-transparent border-primary"
                      class="border border-warning"
                    >
                      <template v-slot:header>
                        <h5 class="my-0 mb-4">
                          Total des transactions échouées
                        </h5>
                      </template>
                      <div class=" float-right">
                        <h1 class="text-primary">{{transaction.echouee}}</h1>
                      </div>
                    </b-card>
                </div>
                <div class="col-md-6">
                    <b-card
                      header-class="bg-transparent border-success"
                      class="border border-success"
                    >
                        <div>
                            <ul>
                                <li>
                                    <span class="text-uppercase" style="color:black; font-weight:bold">PAID :</span> Transaction réussie
                                </li>
                                 <li>
                                    <span class="text-uppercase" style="color:black; font-weight:bold">Failed / ERROR :</span> Solde insuffisant ou transaction rejetée ou numéro invalide
                                </li>
                                 <li>
                                    <span class="text-uppercase" style="color:black; font-weight:bold">Initial :</span> Paiment initialisé
                                </li>
                            </ul>
                        </div> 
                      <p>Le statut <span class="text-uppercase" style="color:black; font-weight:bold">Failed</span> ne signifie pas de manière systématique que la transaction a échoué. Cela voudrait simplement dire que la dernière valeur enregistrée du statut suite à la vérification est <span class="text-uppercase" style="color:black; font-weight:bold">failed</span> </p>
                    </b-card>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">               
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="transactionData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
                            'badge-soft-success': `${row.value}` === 'Paid',
                            'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                      >{{ row.value}}</div>
                    </template>

                  <template v-slot:cell(about)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          
                          'badge-soft-danger': `${row.value}` === 'ERROR' ? 'badge-soft-danger': `${row.value}` === 'FAILED',
                          'badge-soft-success': `${row.value}` === 'PAID',
                          'badge-soft-warning': `${row.value}` === 'INITIAL',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>    
  </Layout>
</template>

<style>

</style>
